body {
  background-color: #EEEEEE;
}

#topbar {
  background-color: #FFFFFF;
  width: 80%;
  text-align: left;
  align-self: center;
  margin-left: 10%;
  margin-right: 10%;
  position: absolute; 
  top: 0px;
}

#topbuttons {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-self: flex-end;
  max-width: 50%;
  flex-shrink: 1;
}

#topbuttons li button {
  background-color: #FFFFFF;
  border: none;
  font-weight: bold;
  font-family: serif;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

#topbuttons li button:hover {
  background-color: #FFFFFF;
  border: none;
  text-decoration: underline;
}

#overallbuttons {
  display: flex;
}

#logout {
  display: flex;
  justify-content: flex-end;
  flex-grow: 5;
  flex-shrink: 5;
}

#logout button {
  background-color: #FFFFFF;
  border: none;
  font-weight: bold;
  font-family: serif;
  padding: 10px;
  margin-right: 5%;
  align-self: right;
}

#logout button:hover {
  background-color: #FFFFFF;
  border: none;
  text-decoration: underline;
}

#toplogo {
  width: auto;
  display: flex; 
  min-width: 100px;
  max-width: 250px;
}

#topbarTop {
  display: flex;
}

#toptext {
  width: auto; 
  flex-grow: 5;
  text-align: center;
  font-family: sans-serif;
  vertical-align: middle;
  position: relative; 
  margin-top: 20px;
}

#rosterrow {
  height: 20px;
}

#rosterheader {
  height: 50px;
  font-family: sans-serif;
  font-size:large;
  background-color:#8c8c8c;
}

#rostertext {
  height: auto;
  flex-grow: 5;
  font-family: sans-serif;
  vertical-align: middle;
  width: 80%;
  align-self: center;
  position: relative; 
}

#student:nth-child(even) {
  background-color: lightgray;
}

#student:nth-child(odd) {
  background-color: white;
}

#school-name {
  font-weight: bold;
}

.main-page {
  width: 80%;
  height: auto;
  align-self: center;
  position: relative;
  top: 150px;
  margin-left: 10%;
  margin-right: 10%;
}

.calendar {
  width: 100%; 
  background-color: #A0A0A0;
  border: 1px solid black;
  border-collapse: collapse;
  font-family: sans-serif;
}

.calendar th, td {
  border-right: 1px solid black;
}

.calendar td {
  text-align: center
}

.calendar tr {
  height: 30px;
}

.calendar #disabled {
  background-color: #B7B7B7;
}

.calendar tr:nth-child(even) {
  background-color: #D9D9D9;
}


#loginBox {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border: 3px solid #303030;
  border-radius: 5px;
  padding: 1%;
  background-color: #D9D9D9
}

#lostPassword button{
  border: none;
  padding: 0%;
  background-color: transparent;
  font-family: serif;
  font-size: 11px;
  color: blue;
  position: absolute;
  right: 5%;
}

#lostPassword button:hover {
  color:red;
}

.calendar tr:nth-child(odd) #disabled {
  background-color: #8F8F8F;
}

.roster {
  width: 100%; 
  background-color: #A0A0A0;
  border: 1px solid black;
  border-collapse: collapse;
  font-family: sans-serif;
}

.roster th, td {
  border-right: 1px solid black;
}

.roster td {
  text-align: center
}

.roster tr {
  height: 30px;
}

.roster #disabled {
  background-color: #B7B7B7;
}

.roster tr:nth-child(even) {
  background-color: #D9D9D9;
}

.roster tr:nth-child(odd) #disabled {
  background-color: #8F8F8F;
}

.roster th, td {
  border-right: 1px solid black;
}

.roster td {
  text-align: center
}

.roster tr {
  height: 30px;
  width: 100%;
}

.roster tr:nth-child(even) {
  background-color: #D9D9D9;
}

.roster tr:nth-child(odd) #disabled {
  background-color: #8F8F8F;
}

.roster table {
  width: 100%;
}

#request {
  border: 1px solid #CDC9C5;
  background: #CDC9C5;
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
  font-family: serif;
}

#top-calendar {
  display: flex;
  align-items: baseline;
}

#top-calendar-title {
  flex-grow: 5;
  text-align: left;
  padding-left: 5px;
  vertical-align: middle;
}

#top-calendar-button {
  flex-grow: 1;
}

#calendar-head {
  height: 40px;
}

#roster-head {
  height: 40px;
}

#top-roster {
  display: flex;
  align-items: baseline;
}

#top-roster-title {
  flex-grow: 5;
  text-align: left;
  padding-left: 5px;
  vertical-align: middle;
}

#top-roster-button {
  flex-grow: 1;
}

.main-spacer {
  height: 75px;
}

.student-request-results {
  border: 1px solid black;
  width: 100%
}

.student-request-results tr td {
  border: none;
  font-family: sans serif;
}

.student-request-results #name {
  width: 33%
}

.student-request-results td {
  width: 16%
}

.student-request-results #req-button {
  text-align: right
}

#request-modal {
  max-height: 600px;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

#bold {
  font-weight: bold;
}

#PRC-status {
  color: #338f40;
  font-weight: bold;
}

#CNC-status {
  color: #b32727;
  font-weight: bold;
}

#overridden-status {
  color: #c9ba49;
  font-weight: bold;
}

#PND-status {
  color: #bec28c;
  font-weight: bold; 
}